import React from "react";
import BreadCrum from "../../components/BreadCrum/BreadCrum";
import DashboardSidebar from "./DashboardSidebar";
import { Link } from "react-router-dom";
import "./Dashboard.css";
import { Helmet } from "react-helmet";

const DashboardAddAddress = () => {
    return (
    <React.Fragment>
        <Helmet>
            <title>Add Address | React Ecommerce</title>
        </Helmet>
        <BreadCrum items={['Dashboard','Address Book','Add Address']} />
        <section className="section">
            <div className="container">
    
                <div className="row">

                    <DashboardSidebar/>

                    {/* Panels (Start) */}
                    <div className="col-lg-9 col-md-9 col-sm-12">
                        <div className="card dashboard-card">
                            <div className="card-header">
                                <h4 className="title">Add Address</h4>
                            </div>
                            <div className="card-body">
                    
                                <div className="row">

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="label">Address Label</label>
                                            <input type="text" name="label" id="label" className="input-group-text input-box" required placeholder="Label" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="name">Name</label>
                                            <input type="text" name="name" id="name" className="input-group-text input-box" required placeholder="Reciver name" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="phone">Phone</label>
                                            <input type="tel" minLength={10} maxLength={10} name="phone" id="phone" className="input-group-text input-box" required placeholder="Phone (10 Digit)" />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="phone-alternate">Phone Alternate</label>
                                            <input type="tel" minLength={10} maxLength={10} name="phone-alternate" id="phone-alternate" className="input-group-text input-box" required placeholder="Phone Alternate (10 Digit)" />
                                        </div>
                                    </div>


                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="street">Street</label>
                                            <input type="text" name="street" id="street" className="input-group-text input-box" placeholder="Area / Locality / Landmark" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="city">City</label>
                                            <input type="text" name="city" id="city" className="input-group-text input-box" placeholder="City (Ex. Mumbai)" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="pincode">Pincode</label>
                                            <input type="number" name="pincode" id="pincode" className="input-group-text input-box" placeholder="Pincode (6 Digit)" required />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="state">State</label>
                                            <select className="form-select" name="state" id="state" required>
                                                <option selected value="">Select State</option>
                                                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                <option value="Assam">Assam</option>
                                                <option value="Bihar">Bihar</option>
                                                <option value="Chandigarh">Chandigarh</option>
                                                <option value="Chhattisgarh">Chhattisgarh</option>
                                                <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                                                <option value="Daman and Diu">Daman and Diu</option>
                                                <option value="Delhi">Delhi</option>
                                                <option value="Goa">Goa</option>
                                                <option value="Gujarat">Gujarat</option>
                                                <option value="Haryana">Haryana</option>
                                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                <option value="Jharkhand">Jharkhand</option>
                                                <option value="Karnataka">Karnataka</option>
                                                <option value="Kerala">Kerala</option>
                                                <option value="Lakshadweep">Lakshadweep</option>
                                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                <option value="Maharashtra">Maharashtra</option>
                                                <option value="Manipur">Manipur</option>
                                                <option value="Meghalaya">Meghalaya</option>
                                                <option value="Mizoram">Mizoram</option>
                                                <option value="Nagaland">Nagaland</option>
                                                <option value="Orissa">Orissa</option>
                                                <option value="Pondicherry">Pondicherry</option>
                                                <option value="Punjab">Punjab</option>
                                                <option value="Rajasthan">Rajasthan</option>
                                                <option value="Sikkim">Sikkim</option>
                                                <option value="Tamil Nadu">Tamil Nadu</option>
                                                <option value="Tripura">Tripura</option>
                                                <option value="Uttaranchal">Uttaranchal</option>
                                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                <option value="West Bengal">West Bengal</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>        
                    
                            </div>
                            <div className="card-footer">
                                <button className="btn btn-ascent me-2">Save changes</button>
                                <Link to={"/dashboard/address"} className="btn btn-light">Cancel</Link>
                            </div>
                        </div>
                    </div>
                    {/* Panels (End) */}

                </div>

            </div>
        </section>
    </React.Fragment>);
}

export default DashboardAddAddress;