import React from "react";
import ProductCard from "../../components/ProductCard/ProductCard";
import { BsArrowRightShort } from "react-icons/all";
import { Helmet } from "react-helmet";

class Home extends React.Component {

    componentDidUpdate() {
        window.addEventListener('load',this.handleWindowLoad);
    }

    handleWindowLoad() {
        document.getElementById('home-nav-link').classList.add('active');
    }

    render() {
        return (
            <React.Fragment>

                <Helmet>
                    <title>Home React Ecommerce</title>
                </Helmet>

                {/* Carousel Section (Start) */}
                <section className="section bg-light border-0">
                    <div className="container">

                        <div className="row">

                            <div className="col-lg-3 col-md-3 col-sm-12 d-lg-block d-md-block d-none">
                                <div className="card">
                                    <nav className="side-nav">
                                        <a href="#" className="btn sidebar-tab side-tab">Latest Products</a>
                                        <a href="#" className="btn sidebar-tab side-tab">Latest Products</a>
                                        <a href="#" className="btn sidebar-tab side-tab">Latest Products</a>
                                        <a href="#" className="btn sidebar-tab side-tab">Latest Products</a>
                                        <a href="#" className="btn sidebar-tab side-tab">Latest Products</a>
                                        <a href="#" className="btn sidebar-tab side-tab">Latest Products</a>
                                        <a href="#" className="btn sidebar-tab side-tab">Latest Products</a>
                                    </nav>
                                </div>
                            </div>

                            <div className="col-lg-9 col-md-9 col-sm-12">
                                <div id="front-carousel" class="carousel slide card rounded" data-bs-ride="carousel">
                                    <div class="carousel-indicators">
                                        <button type="button" data-bs-target="#front-carousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#front-carousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#front-carousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    </div>
                                    <div class="carousel-inner rounded">
                                        <div class="carousel-item w-100 active">
                                        <img src="images/banner.jpg" class="d-block w-100" alt="..."/>
                                        </div>
                                        <div class="carousel-item w-100 ">
                                        <img src="images/banner.jpg" class="d-block w-100" alt="..."/>
                                        </div>
                                        <div class="carousel-item w-100 ">
                                        <img src="images/banner.jpg" class="d-block w-100" alt="..."/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
                {/* Carousel Section (End) */}

                {/* Product Section (Start) */}
                <section className="section">
                    <div className="container">

                        {/* Product Row (Start) */}
                        <div className="row">

                            <div className="col-12">
                                <div className="section-title">
                                    <h3>Latest Products</h3>
                                    <a href="#" className="card-link">View more<span><BsArrowRightShort size={25}/></span> </a>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-6">
                                <ProductCard/>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <ProductCard/>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <ProductCard/>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <ProductCard/>
                            </div>

                            

                        </div>
                        {/* Product Row (End) */}

                        {/* Banner Row (Start) */}
                        <div className="row mt-5">

                            <div className="col-12">
                                <div className="banner-card-div">
                                    <div className="card banner-card" style={{backgroundImage: 'linear-gradient(to right, #6a11cb 0%, rgba(0,0,0,0) 100%),url("/images/banner/banner-1.jpg")'}}>
                                        <div className="card-body">
                                            <h6>Best Jeans</h6>
                                            <h3>Trending Fashion</h3>
                                            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
                                            <button className="btn btn-ascent btn-sm active">Show now</button>
                                        </div>
                                    </div>    
                                </div>
                            </div>

                        </div>
                        {/* Banner Row (End) */}

                    </div>
                </section>
                {/* Product Section (End) */}

                {/* Product Section (Start) */}
                <section className="section">
                    <div className="container">

                        {/* Product Row (Start) */}
                        <div className="row">

                            <div className="col-12">
                                <div className="section-title">
                                    <h3>Latest Products</h3>
                                    <a href="#" className="card-link">View more<span><BsArrowRightShort size={25}/></span> </a>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-6">
                                <ProductCard/>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <ProductCard/>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <ProductCard/>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <ProductCard/>
                            </div>

                        </div>
                        {/* Product Row (End) */}

                        {/* Banner Row (Start) */}
                        <div className="row mt-5">

                            <div className="col-12">
                                <div className="banner-card-div">
                                    <div className="card banner-card" style={{backgroundImage: 'linear-gradient(to right, #6a11cb 0%, rgba(0,0,0,0) 100%),url("/images/banner/banner-2.jpg")'}}>
                                        <div className="card-body">
                                            <h6>Best Jeans</h6>
                                            <h3>Trending Fashion</h3>
                                            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
                                            <button className="btn btn-ascent btn-sm active">Show now</button>
                                        </div>
                                    </div>    
                                </div>
                            </div>

                        </div>
                        {/* Banner Row (End) */}

                    </div>
                </section>
                {/* Product Section (End) */}

                {/* Product Section (Start) */}
                <section className="section">
                    <div className="container">

                        {/* Product Row (Start) */}
                        <div className="row">

                            <div className="col-12">
                                <div className="section-title">
                                    <h3>Latest Products</h3>
                                    <a href="#" className="card-link">View more<span><BsArrowRightShort size={25}/></span> </a>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4 col-6">
                                <ProductCard/>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <ProductCard/>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <ProductCard/>
                            </div>
                            <div className="col-lg-3 col-md-4 col-6">
                                <ProductCard/>
                            </div>

                        </div>
                        {/* Product Row (End) */}

                        {/* Banner Row (Start) */}
                        <div className="row mt-5">

                            <div className="col-12">
                                <div className="banner-card-div">
                                    <div className="card banner-card" style={{backgroundImage: 'linear-gradient(to right, #6a11cb 0%, rgba(0,0,0,0) 100%),url("/images/banner/banner-3.jpg")'}}>
                                        <div className="card-body">
                                            <h6>Best Jeans</h6>
                                            <h3>Trending Fashion</h3>
                                            <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit.</p>
                                            <button className="btn btn-ascent btn-sm active">Show now</button>
                                        </div>
                                    </div>    
                                </div>
                            </div>

                        </div>
                        {/* Banner Row (End) */}

                    </div>
                </section>
                {/* Product Section (End) */}


            </React.Fragment>
        )
    }
}

export default Home;