import axios from "axios";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

const handleLogin = async (email,password) => {

    axios.defaults.withCredentials = false;
    axios.post("http://localhost:8000/api/login",{
        email: email,
        password: password
    }).then((res) => {
        if (res.status == 201) {
            localStorage.setItem('user', JSON.stringify(res.data));
            toast.success("Welcome back!");
        }
    }).catch((err) => {
        console.warn(err);
    })
}

const getUser = () => {
    if (localStorage.getItem('user')) {
        return JSON.parse(localStorage.getItem('user'));
    }
    else {
        return false;
    }
}

const handleLogout = () => {
    const result = localStorage.clear();
    toast.success('Logged out');
}

export { handleLogin, getUser, handleLogout};