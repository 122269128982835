import React from "react";
import BreadCrum from "../../components/BreadCrum/BreadCrum";
import DashboardSidebar from "./DashboardSidebar";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import { IoMdDownload } from "react-icons/all";
import { Helmet } from "react-helmet";

const DashboardOrderDetails = () => {
    return (
    <React.Fragment>
        <Helmet>
            <title>Order Details | React Ecommerce</title>
        </Helmet>
        <BreadCrum items={['Dashboard','Orders','Details']} />
        <section className="section">
            <div className="container">
    
                <div className="row">

                    <DashboardSidebar/>

                    {/* Panels (Start) */}
                    <div className="col-lg-9 col-md-9 col-sm-12">
                        <div className="card dashboard-card">
                            <div className="card-header">
                                <h4 className="title">Order Details</h4>
                            </div>
                            <div className="card-body p-0">

                                <div className="row">

                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="card">
                                            <div className="card-body pb-0">
                                                <h6 className="title">Information</h6>
                                                <p className="description">Order ID - 12345678</p>
                                                <p className="description">Order Date : 25-09-2021</p>
                                                <p className="description">Order Status : Confirmed</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="card">
                                            <div className="card-body pb-0">
                                                <h6 className="title">Deliver to</h6>
                                                <p className="description">Labhansh Satpute</p>
                                                <p className="description">Near post office, sector 36, Noida - 123456</p>
                                                <p className="description">Phone : 9876543210, 9876543210</p>
                                                <p className="description">Delivery Status : Deliverd</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body pb-2">
                                                <h6 className="title">Payment : $ 50.00 </h6>
                                                <p className="description">Payment Method : Online Payment</p>
                                                <p className="description">Payment Status : Payment Success</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body pb-2">
                                                <h6 className="title">Orderd items</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="card order-item-card">
                                            <div className="card-body">
                                                <Link to={"/product"}><img src="/images/product-img-1.jpg" alt="product-name" className="img-fluid img-thumbnail" /></Link>
                                                <div className="product-details">
                                                    <h6 className="title">Jack & Jones Hoody</h6>
                                                    <p className="description">Quantity : 2 Units</p>
                                                    <p className="description">Price : $ 40.00</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="card order-item-card">
                                            <div className="card-body">
                                                <Link to={"/product"}><img src="/images/product-img-1.jpg" alt="product-name" className="img-fluid img-thumbnail" /></Link>
                                                <div className="product-details">
                                                    <h6 className="title">Jack & Jones Hoody</h6>
                                                    <p className="description">Quantity : 2 Units</p>
                                                    <p className="description">Price : $ 40.00</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-12">
                                        <div className="card order-item-card">
                                            <div className="card-body">
                                                <Link to={"/product"}><img src="/images/product-img-1.jpg" alt="product-name" className="img-fluid img-thumbnail" /></Link>
                                                <div className="product-details">
                                                    <h6 className="title">Jack & Jones Hoody</h6>
                                                    <p className="description">Quantity : 2 Units</p>
                                                    <p className="description">Price : $ 40.00</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="card-footer d-flex justify-content-between align-items-center">
                                <button className="btn btn-sm btn-ascent"> <span className="pb-1 me-1"><IoMdDownload size={15}/></span> Download invoice</button>
                                <a href="#" className="card-link text-danger">Request cancellation</a>
                            </div>
                        </div>
                    </div>
                    {/* Panels (End) */}

                </div>

            </div>
        </section>
    </React.Fragment>)
}

export default DashboardOrderDetails;