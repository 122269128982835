import React from "react";
import BreadCrum from "../../components/BreadCrum/BreadCrum";
import DashboardSidebar from "./DashboardSidebar";
import { Link } from "react-router-dom";
import "./Dashboard.css";

const OrderCard = () => {
    return (
    <React.Fragment>
        <div className="card order-card">
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                        <h6 className="title">Order Items : Blue Jeans, Red T-Shirt</h6>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 text-lg-end text-md-end">
                        <h6 className="title">$500.00</h6>
                    </div>
                </div>
                <div className="delivery-to">
                    <p className="description">Deliver to</p>
                    <p className="description">Labhansh Satpute</p>
                    <p className="description">Near Post Office, Sector 36, Noide-123456</p>
                </div>
                <div className="actions">
                    <p className="btn btn-sm px-3 alert-ascent me-2">Ariving</p>
                    <Link to={"/dashboard/orders/details"} className="btn btn-ascent btn-sm me-2">Order details</Link>
                </div>
            </div>
        </div>
    </React.Fragment>)
}

const DashboardOrders = () => {
    return (
    <React.Fragment>
        <BreadCrum items={['Dashboard','My Orders']} />
        <section className="section">
            <div className="container">
        
                <div className="row">
    
                    <DashboardSidebar/>
    
                    {/* Panels (Start) */}
                    <div className="col-lg-9 col-md-9 col-sm-12">
                        <div className="card dashboard-card">
                            <div className="card-header">
                                <h4 className="title">My Orders</h4>
                            </div>
                            <div className="card-body p-0">

                                <OrderCard/>
                                <OrderCard/>
                                <OrderCard/>
                            </div>
                        </div>
                    </div>
                    {/* Panels (End) */}
    
                </div>
    
            </div>
        </section>
    </React.Fragment>);
}

export default DashboardOrders;