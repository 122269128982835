import React, { useState } from "react";
import BreadCrum from "../../components/BreadCrum/BreadCrum";
import { Link } from "react-router-dom";
import { FiTrash2, BiHeart, BiPlus, MdKeyboardArrowRight } from "react-icons/all";
import "./Cart.css";

const ProductCard = () => {

    const [quantity,setQuantity] = useState(1);
    const [disableDecrease,enaableDisableDecrease] = useState('');
    const [size,setSize] = useState(['inactive','inactive','inactive']);

    const handleSizeChange = (index) => {
        switch (index) {
            case 0: setSize({0:'active',1:'inactive',2:'inactive'}); break;
            case 1: setSize({0:'inactive',1:'active',2:'inactive'}); break;
            case 2: setSize({0:'inactive',1:'inactive',2:'active'}); break;
            default: break;
        }
    }

    const handleIncreaseQuantity = () => {
        if (quantity >= 1) {
            setQuantity(quantity + 1); 
            enaableDisableDecrease('');
        }
        else { enaableDisableDecrease('disabled'); }      
    }

    const handleDecreaseQuantity = () => {
        if (quantity == 1) {
            enaableDisableDecrease('disabled');
        }
        else { 
            setQuantity(quantity - 1);
            enaableDisableDecrease('');
        }
    }

    return (
    <React.Fragment>
        <figure className="card product-card-cart">
            <div className="card-body">
                <Link to={"/product"} className="card-link"><img src="/images/product-img-1.jpg" alt="product-img" className="product-img img-fluid" /></Link>
                <div className="details">
                    <h6 className="product-name">Jack & Jones Hoody</h6>
                    <div className="d-flex align-items-baseline mt-2">
                        <h6 className="product-price-new">$50.00</h6>
                        <h6 className="product-price-old">$98.00</h6>
                    </div>
                    <div className="d-flex flex-lg-row flex-md-row flex-column">
                        <div className="me-3 w-auto mt-3">
                            <div className="quantity-button-div d-flex">
                                <button className="btn quantity-button" disabled={disableDecrease} onClick={() => handleDecreaseQuantity()}>-</button>
                                <input type="text" name="quantity" readOnly value={quantity} className="input-group-text input-box py-1" />
                                <button className="btn quantity-button" onClick={() => handleIncreaseQuantity()}>+</button>
                            </div>
                        </div>
                        <div className="w-auto mt-3">
                            <div className="size-button-div">
                                <button className={'size-button ' + size[0]} onClick={() => handleSizeChange(0)}>Small</button>
                                <button className={'size-button ' + size[1]} onClick={() => handleSizeChange(1)}>Medium</button>
                                <button className={'size-button ' + size[2]} onClick={() => handleSizeChange(2)}>Large</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer">
                <button className="btn alert-ascent me-2">Add to wishlist<BiHeart size={13} className="ms-2"/></button>
                <button className="btn alert-red">Remove<FiTrash2 size={12} className="ms-2"/></button>
            </div>
        </figure>
    </React.Fragment>);
}

class Cart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
        <React.Fragment>
        <BreadCrum items={['My Cart']} />
            <section className="section">
                <div className="container">

                    <div className="section-title">
                        <h3>My Cart</h3>
                        <a className="card-link">3 Items</a>
                    </div>

                    <div className="row">

                        <div className="col-lg-8">
                            <div className="cart-items">
                                <ProductCard/>
                                <ProductCard/>
                                <ProductCard/>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <figure className="card">
                                <div className="card-header">
                                    <h4 className="title">Order Summary</h4>
                                </div>
                                <div className="card-body">
                                    <p className="description mb-3">Check coupon code applicability</p>
                                    <div className="input-group w-100">
                                        <input type="text" name="coupon-code" id="coupon-code" className="input-box input-group-text w-75" placeholder="Apply Coupon Code" />
                                        <button className="btn btn-ascent w-25">Apply</button>
                                    </div><br />
                                    <hr className="w-100 mt-2" />
                                    <table className="table order-summary table-borderless mb-0 w-100">
                                        <tbody>
                                            <tr>
                                                <td className="text-start"><h6 className="heading">Sub Total</h6></td>
                                                <td className="text-end"><h6 className="data">+ $50.00</h6></td>
                                            </tr>
                                            <tr>
                                                <td className="text-start"><h6 className="heading">Shipping</h6></td>
                                                <td className="text-end"><h6 className="data">+ $10.00</h6></td>
                                            </tr>
                                            <tr>
                                                <td className="text-start"><h6 className="heading">GST</h6></td>
                                                <td className="text-end"><h6 className="data">+ $10.00</h6></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <hr />
                                    <table className="table order-summary table-borderless mb-0 w-100">
                                        <tbody>
                                            <tr>
                                                <td className="text-start pb-0"><h6 className="heading total">Total</h6></td>
                                                <td className="text-end pb-0"><h6 className="heading total">+ $100.00</h6></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-footer text-center">
                                    <button className="btn btn-ascent w-100 py-2">Place Order<MdKeyboardArrowRight size={25}/></button>
                                </div>
                                <div className="card-footer text-center">
                                    <Link to={"/"} className="card-link mx-auto">Continue Shopping</Link>
                                </div>
                            </figure>
                        </div>

                    </div>

                </div>
            </section>
        </React.Fragment>)
    }
}

export default Cart;