import React from "react";

const BreadCrum = (props) => {

    const breadcrumbItems = props.items.map((data) => {
        return (<li class="breadcrumb-item"><a href="#" className="card-link"> {data} </a></li>);
    })

    return (
    <React.Fragment>
        <section className="section p-3 bg-light border-bottom">
                <div className="container my-1">
                    <nav>
                        <ol class="breadcrumb mb-0">
                            <li class="breadcrumb-item"><a href="#" className="card-link">Home</a></li>
                            {breadcrumbItems}
                        </ol>
                    </nav>
                </div>
        </section>
    </React.Fragment>);
}

export default BreadCrum;