import React from "react";
import { Link } from "react-router-dom";
import { FaStar, AiOutlineHeart } from "react-icons/all";
import "./ProductCard.css";
import { toast } from 'react-toastify';

const ProductCard = () => {

    const handleAddToWishlist = () => toast.success("Added to wishlist");
    
    return (
    <React.Fragment>
        <div className="product-card-div">
            
            <figure className="card product-card">
            <Link to="/product"><img src="images/product-img.jpg" alt="product-img" className="card-img-top img-fluid" />
            </Link>
                <div className="card-body">
                <Link to="/product"><h6 className="product-name">Sony Headphones</h6></Link>
                    <div className="rating-stars">
                        <span className="active"><FaStar size={15}/></span>
                        <span className="active"><FaStar size={15}/></span>
                        <span className="active"><FaStar size={15}/></span>
                        <span className="active"><FaStar size={15}/></span>
                        <span className="inactive"><FaStar size={15}/></span>
                    </div>
                    <div className="row">
                        <div className="col-9">
                            <h5 className="price">
                                <span className="new">$78.00</span>
                                <span className="old">$78.00</span>
                            </h5>
                        </div>
                        <div className="col-3">
                            <button onClick={handleAddToWishlist} className="add-to-wishlist"data-bs-toggle="tooltip" data-bs-placement="top" title="Add to wishlist"><AiOutlineHeart size={27}/></button>
                        </div>
                    </div>
                </div>
            </figure>
        </div>
    </React.Fragment>);
}

const ProductCardList = () => {
    const handleAddToCart = () => toast.success("Added to cart");
    
    return (
    <React.Fragment>
        <div className="col-lg-4 col-md-4 col-6 p-lg-1">
        <div className="product-card-div">
            <figure className="card product-card">
            <Link to="/product"><img src="/images/product-img.jpg" alt="product-img" className="card-img-top img-fluid" />
            </Link>
                <div className="card-body p-3">
                <Link to="/product"><h6 className="product-name mb-0">Sony Headphones</h6></Link>
                    <div className="row">
                        <div className="col-6">
                            <h5 className="price">
                                <span className="new">$78.00</span>
                                <span className="old">$78.00</span>
                            </h5>
                        </div>
                        <div className="col-12">
                            <button onClick={handleAddToCart} className="btn btn-ascent w-100 mt-3" data-bs-toggle="tooltip" data-bs-placement="top" title="Add to cart">Add to cart</button>
                        </div>
                    </div>
                </div>
            </figure>
        </div>
        </div>
    </React.Fragment>);   
}

export default ProductCard;
export { ProductCardList };