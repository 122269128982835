import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { BiUserCircle, HiOutlineLocationMarker, HiOutlineShoppingCart, FiSettings, MdOutlineLogout } from "react-icons/all";
import { handleLogout } from "../../controllers/Auth";

const DashboardSidebar = () => {

    const location = useLocation();
    const path = location.pathname;

    const [tab,setTab] = useState(['inactive','inactive','inactive','inactive']);

    const TogglePanel = (tabIndex) => {
        switch (tabIndex) {
            case 0: setTab({0:'active',1:'inactive',2:'inactive',3:'inactive'}); break;
            case 1: setTab({0:'inactive',1:'active',2:'inactive',3:'inactive'}); break;
            case 2: setTab({0:'inactive',1:'inactive',2:'active',3:'inactive'}); break;
            case 3: setTab({0:'inactive',1:'inactive',2:'inactive',3:'active'}); break;
            default: break;
        }
    }

    useEffect(() => {

        const LoadPage = () => {
            if (path.includes('account')) {
                TogglePanel(0);
            }
            else if (path.includes('address')) {
                TogglePanel(1);
            }
            else if (path.includes('orders')) {
                document.getElementById('dashboard-tabs').scrollTo(900,0)
                TogglePanel(2);
            }
            else if (path.includes('settings')) {
                document.getElementById('dashboard-tabs').scrollTo(900,0)
                TogglePanel(3);
            }
            else {
                TogglePanel(0);
            }
        }

        LoadPage();
        
    },[])
    
    return (
    <React.Fragment>

        {/* Title */}
        <div className="col-12">
            <div className="section-title">
                <h3>My Dashboard</h3>
                <button onClick={() => handleLogout()} className="card-link">Logout <span className="ms-1"><MdOutlineLogout size={17}/></span></button>
            </div>
        </div>

        {/* Sidebar (Start) */}
        <div className="col-lg-3 col-md-3 col-sm-12">
            <aside className="dashboard-tabs" id="dashboard-tabs">
                <ul className="list-unstyled dashboard-tab-list">
                    <li><Link to={"/dashboard/account"} className={'dashboard-tab ' + tab[0]}><span className="tab-icon"><BiUserCircle size={23} /></span> My Account</Link></li>

                    <li><Link to={"/dashboard/address"} className={'dashboard-tab ' + tab[1]}><span className="tab-icon"><HiOutlineLocationMarker size={23} /></span> Address Book</Link></li>

                    <li><Link to={"/dashboard/orders"} className={'dashboard-tab ' + tab[2]}><span className="tab-icon"><HiOutlineShoppingCart size={23} /></span> My Orders</Link></li>

                    <li><Link to={"/dashboard/settings"} className={'dashboard-tab ' + tab[3]}><span className="tab-icon"><FiSettings size={23} /></span> Settings</Link></li>
                </ul>
            </aside>
        </div>
        {/* Sidebar (End) */}

    </React.Fragment>);
}

export default DashboardSidebar;