import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";

import {AiFillHeart, AiOutlineHome, HiMenuAlt3, HiMenuAlt2, BsPersonFill, IoBagHandle, RiSearchLine, HiMenu, IoIosMail, BiUserCircle, HiOutlineLocationMarker, HiOutlineShoppingCart, FiSettings} from "react-icons/all";

const Header = () => {

    const [sidebarStatus, sidebarActive] = useState('inactive');
    const [mobileTopNav, mobileTopNavActive] = useState(0+'px');

    useEffect( ()=> {
        window.addEventListener('scroll',hideTopNavbar);
    })

    const hideTopNavbar = () => {
        if (window.pageYOffset > 100) {
            mobileTopNavActive('-' + document.getElementById('mobile-top-nav').clientHeight + 'px');
        }
        else { mobileTopNavActive('0px'); }
    }

    const ToggleSidebar = () => {
        if (sidebarStatus == 'inactive') { sidebarActive('active'); }
        else { sidebarActive('inactive'); }
    }

    return (
    <React.Fragment>

        {/* Desktop Header (Start) */}
        <header className="header" id="desktop-header">

            {/* Top Navigation (Start) */}
            <nav className="navbar navbar-expand" id="top-nav">
                <div className="container">
                    <div className="row w-100">

                        {/* Logo */}
                        <div className="col-lg-3 col-md-3 col-3 d-flex justify-content-start align-items-center">
                            <Link to="/" className="navbar-brand "><span className="logo"><span className="nav-icon"><HiOutlineShoppingCart className="mb-2" size={25}/></span> E - Commerce</span></Link>
                        </div>

                        {/* Search Box */}
                        <div className="col-lg-6 col-md-6 col-6">
                            <div className="input-group search-box">
                                <select name="search-type" className="form-select">
                                    <option value="All">All Product</option>
                                    <option value="All">All Product</option>
                                    <option value="All">All Product</option>
                                </select>
                                <input type="search" name="search" className="input-group-text input-box" placeholder="Search products"/>
                                <button className="btn btn-ascent"><RiSearchLine size={22}/></button>
                            </div>
                        </div>
                        
                        {/* Cart, Account, Wishlist */}
                        <div className="col-lg-3 col-md-3 col-3 text-end">
                            <div className="collapse navbar-collapse">
                                <ul className="navbar-nav ms-auto">
                                    <li className="nav-item ms-2">
                                        <Link to={"/cart"} className="nav-link"><span className="icon"><AiFillHeart size={20}/><span className="icon-badge">2</span></span></Link>
                                    </li>
                                    <li className="nav-item ms-2">
                                        <Link to={"/cart"} className="nav-link"><span className="icon"><IoBagHandle size={20}/></span></Link>
                                    </li>
                                    <li className="nav-item ms-2">
                                        <Link to={"/dashboard/account"} className="nav-link"><span className="icon"><BsPersonFill size={20}/></span>
                                        <div className="nav-link-caption">
                                            <label>Labhansh Satpute</label>
                                            <hr className="px-2"/>
                                            <Link to={"/signin"} className="card-link">labhansh25@gmail.com</Link>
                                        </div>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>

                </div>
            </nav>
            {/* Top Navigation (End) */}

            {/* Bottom Navigation (Start) */}
            <nav className="navbar navbar-expand" id="bottom-nav">
                <div className="container">
                    <ul className="navbar-nav">
                        <li className="nav-item ms-0"><a href="#" className="nav-link ignore ps-0"><span className="nav-icon me-2"><HiMenu size={20}/></span> All Categories</a></li>
                        <li className="nav-item"><Link to="/" className="nav-link" id="home-nav-link">Home</Link></li>
                        <li className="nav-item"><Link to="/product/list" className="nav-link" id="product-nav-link">Products</Link></li>
                        <li className="nav-item"><Link to="/dashboard/account" className="nav-link" id="account-nav-link">My Dashboard</Link></li>
                        <li className="nav-item"><Link to="/" className="nav-link" id="about-nav-link">About us</Link></li>
                        <li className="nav-item"><Link to="/" className="nav-link" id="contact-nav-link">Contact us</Link></li>
                    </ul>
                    <ul className="navbar-nav">
                        <li className="nav-item"><a href="#" className="nav-link ignore"><span className="nav-icon me-2"><IoIosMail size={20} /></span> Mail us at: mail@example.com</a></li>
                    </ul>
                </div>
            </nav>
            {/* Bottom Navigation (End) */}

        </header>
        {/* Desktop Header (End) */}


        {/* Mobile Header (Start) */}
        <header className="header" id="mobile-header">

            {/* Top Navigation (Start) */}
            <nav className="navbar navbar-expand" style={{marginTop: mobileTopNav, transition: '0.2s ease'}} id="mobile-top-nav">
                <div className="row card-body">
                    <div className="col-1">
                        <button onClick={() => ToggleSidebar()} className="btn border-0 py-1 px-0 pr-2"><HiMenuAlt2 size={23}/></button>
                    </div>
                    <div className="col-5">
                        <Link to="/" className="navbar-brand"><span className="logo">E - Commerce</span></Link>
                    </div>
                    <div className="col-6">
                        <div className="collapse navbar-collapse">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item mx-0"><a className="nav-link" data-bs-toggle="tooltip" data-bs-placement="top" title="My Wishlist"><span className="icon"><AiFillHeart size={20}/><span className="icon-badge">2</span></span></a></li>
                            <li className="nav-item mx-0"><Link to="/cart" className="nav-link" data-bs-toggle="tooltip" data-bs-placement="top" title="My Cart"><span className="icon"><IoBagHandle size={20}/></span></Link></li>
                            <li className="nav-item mx-0"><Link to="/dashboard/account" className="nav-link pe-0" data-bs-toggle="tooltip" data-bs-placement="top" title="My Account"><span className="icon"><BsPersonFill size={20}/></span></Link></li>
                            </ul>
                        </div>  
                    </div>
                </div>
            </nav>
            {/* Top Navigation (End) */}

            {/* Bottom Search Box (Start) */}
            <nav className="navbar navbar-expand-sm border-top">
                <div className="card-body">
                    <div className="input-group search-box">
                        <input type="search" name="search" className="input-group-text input-box w-75" placeholder="Search products"/>
                        <button className="btn btn-ascent w-25">Search</button>
                    </div>
                </div>
            </nav>
            {/* Bottom Search Box (End) */}

        </header>
        {/* Mobile Header (End) */}

        {/* Mobile Sidebar (Start) */}
        <aside className={'sidebar ' + sidebarStatus}>

            {/* User Information */}
            <div className="card-body bg-light">
                <div className="row">
                    <div className="col-4">
                        <img src="../images/user-img.jpg" alt="user-img" className="user-img img-fluid" />
                    </div>
                    <div className="col-8">
                        <button onClick={ToggleSidebar} className="btn p-0 float-end text-secondary"><HiMenuAlt3 size={25}/></button>
                    </div>
                    <div className="col-12">
                        <h6 className="user-name">Labhansh Satpute</h6>
                        <p className="user-email">labhansh25@gmail.com</p>
                    </div>
                </div>
            </div>

            {/* Sidebar Tabs */}
            <ul className="list-unstyled">

                <li><Link to={"/"} onClick={()=> ToggleSidebar()} className="sidebar-tab"><span className="sidebar-icon"><AiOutlineHome size={23} /></span>Home</Link></li>

                <li><Link to={"/dashboard/account"} onClick={()=> ToggleSidebar()} className="sidebar-tab active"><span className="sidebar-icon"><BiUserCircle size={23} /></span>My Dashboard</Link></li>

                <li><Link to={"/product/list"} onClick={()=> ToggleSidebar()} className="sidebar-tab"><span className="sidebar-icon"><HiOutlineLocationMarker size={23} /></span>Products</Link></li>

                <li><Link to={"/dashboard/orders"} onClick={()=> ToggleSidebar()} className="sidebar-tab"><span className="sidebar-icon"><HiOutlineShoppingCart size={23} /></span>My Orders</Link></li>

                <li><Link to={"/"} onClick={()=> ToggleSidebar()} className="sidebar-tab"><span className="sidebar-icon"><FiSettings size={23} /></span> Settings</Link></li>
            </ul>
        </aside>
        {/* Mobile Sidebar (End) */}

    </React.Fragment>);
}

export default Header;

