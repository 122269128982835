import React from "react";
import "./Footer.css";

import { BsTwitter, BsInstagram, BsFacebook, BiCheckShield, BsArrowRepeat, MdDeliveryDining, FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/all';

const Footer = () => {
    return (
        <React.Fragment>
            <footer className="footer">
                <div className="container top">

                    <div className="row">

                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="card footer-card">
                                <div className="card-body">
                                    <span className="footer-card-icon"><MdDeliveryDining size={50}/></span>
                                    <div>
                                       <h6 className="title">Free Delivery</h6>
                                        <p className="description">Get free delivery order of more than 100$  </p> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="card footer-card ignore">
                                <div className="card-body">
                                    <span className="footer-card-icon"><BiCheckShield size={50}/></span>
                                    <div>
                                       <h6 className="title">SSL Secure Payment</h6>
                                        <p className="description">Safe & secure online payment via razorpay</p> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="card footer-card ignore">
                                <div className="card-body">
                                    <span className="footer-card-icon"><BsArrowRepeat size={50}/></span>
                                    <div>
                                       <h6 className="title">Easy Return & Exchange</h6>
                                        <p className="description">Return or exchange your order very easily</p> 
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
                <hr className="bg-white my-0" />
                <div className="container bottom">
                    <div class="row">

                        <div className="col-lg-3 col-md-12 col-12 pe-4">
                        <h6 className="footer-list-title">E-Commerce</h6>
                            <ul className="list-unstyled">
                                <li className="footer-list-item"><a href="#" className="footer-link"><span className="me-2"><FaMapMarkerAlt size={14}/></span>Sector 36, Noida - 201245, India</a></li>
                                <li className="footer-list-item"><a href="#" className="footer-link"><span className="me-2"><FaPhoneAlt size={14}/></span>Phone : +91-9876543210</a></li>
                                <li className="footer-list-item"><a href="#" className="footer-link"><span className="me-2"><FaEnvelope size={14}/></span>Mail : mail@example.com</a></li>
                            </ul>
                            <ul className="social-media list-unstyled mb-lg-0 mb-md-5">
                                <li><a href="#"><BsFacebook color="#0a66c2" size={20}/></a></li>
                                <li><a href="#"><BsTwitter color="#1da1f2" size={20}/></a></li>
                                <li><a href="#"><BsInstagram color="#5B51D8" size={20}/></a></li>
                            </ul>
                        </div>
                        
                        <div className="col-lg-2 col-md-4 col-12">
                            <h6 className="footer-list-title">Categories</h6>
                            <ul className="list-unstyled mb-lg-0 mb-md-5">
                                <li className="footer-list-item"><a href="#" className="footer-link">Trending Fashion</a></li>
                                <li className="footer-list-item"><a href="#" className="footer-link">Latest Jeans</a></li>
                                <li className="footer-list-item"><a href="#" className="footer-link">Boys Fashion</a></li>
                                <li className="footer-list-item"><a href="#" className="footer-link">Girls Trensing</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-md-4 col-12">
                            <h6 className="footer-list-title">Account</h6>
                            <ul className="list-unstyled mb-lg-0 mb-md-5">
                                <li className="footer-list-item"><a href="#" className="footer-link">My Account</a></li>
                                <li className="footer-list-item"><a href="#" className="footer-link">Cart</a></li>
                                <li className="footer-list-item"><a href="#" className="footer-link">Wishlist</a></li>
                                <li className="footer-list-item"><a href="#" className="footer-link">Support</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-md-4 col-12">
                            <h6 className="footer-list-title">Policies</h6>
                            <ul className="list-unstyled mb-lg-0 mb-md-5">
                                <li className="footer-list-item"><a href="#" className="footer-link">About us</a></li>
                                <li className="footer-list-item"><a href="#" className="footer-link">Cancellation & Refund</a></li>
                                <li className="footer-list-item"><a href="#" className="footer-link">Privacy Policy</a></li>
                                <li className="footer-list-item"><a href="#" className="footer-link">Terms & Conditions</a></li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-12 col-12">
                            <h6 className="footer-list-title">Newsletters</h6>
                            <ul className="list-unstyled">
                                <li className="footer-list-item"><p className="description mb-2">Subscribe to newsletters for latest updates</p></li>
                                <li className="footer-list-">
                                    <div className="input-group">
                                        <input type="email" name="email" id="email" className="input-group-text bg-white input-box w-50 text-dark" placeholder="Email address" />
                                        <button className="btn btn-ascent">Submit</button>
                                    </div>
                                </li>
                                <li className="footer-list-item">
                                    <img src="/images/payments.png" alt="payment-methods" className="img-fluid mt-2" width="230" />
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div className="copyright-div">
                    <p>© 2021 LabhanshSatpute.com | All Rights Reserved.</p>
                </div>
            </footer>
        </React.Fragment>
    );
}

export default Footer;