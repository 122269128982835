import React, {useState} from "react";
import "./ProductList.css";
import { Helmet } from "react-helmet";
import BreadCrum from "../../components/BreadCrum/BreadCrum";
import {HiChevronDown} from "react-icons/all";
import { ProductCardList } from "../../components/ProductCard/ProductCard";
import axios from "axios";

const FilterCard = (props) => {

    const [toggle,setToggle] = useState('active');

    return (
    <React.Fragment>
        <figure className={"card filter-card " + toggle}>
            <div className="card-header" onClick={() => {(toggle == 'active') ? setToggle('inactive') : setToggle('active')}}>
                <h6 className="filter-title">{props.title}</h6>
                <span className="filter-card-toggler"><HiChevronDown size={20}/></span>
            </div>
            <div className="card-body">
                {props.children}
            </div>
        </figure>
    </React.Fragment>);
}

class ProductList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            products: []
        }
        this.handleFetchProducts = this.handleFetchProducts.bind(this);
    }

    handleFetchProducts = async () => {
        axios.get("http://localhost:8000/api/all/products",)
        .then((res) => {
            res.data.forEach((element) => {
                this.state.products.push(element.id);
                console.log(element);
            });

            // console.log(res.data);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    componentDidMount() {
        this.handleFetchProducts();
            console.log(this.state.products);
        // });
    }

    render() {

        // this.state.products.map((id) => {
        //     console.log(id);
        // })

        return (
        <React.Fragment>
            <Helmet>
                <title>Product Name | React Ecommerc</title>
            </Helmet>
            <BreadCrum items={['Product List']} />
            <section className="section">
                <div className="container">

                    <div className="section-title">
                        <h3>Showing result for Product</h3>
                    </div>

                    <div className="row">

                        <div className="col-lg-3 col-md-4 col-sm-12">
                            <aside className="filter-sidebar">
                                <FilterCard title={"Product Categories"}>
                                    <div class="form-check">
                                        <input class="form-check-input" name="product-category" type="radio" id="radio-box-1"/>
                                        <label class="form-check-label" for="radio-box-1">Mens Jeans</label>
                                    </div>
                                    <div class="form-check mt-2">
                                        <input class="form-check-input" name="product-category" type="radio" id="radio-box-2"/>
                                        <label class="form-check-label" for="radio-box-2">Womens Jeans</label>
                                    </div>
                                    <div class="form-check mt-2">
                                        <input class="form-check-input" name="product-category" type="radio" id="radio-box-3"/>
                                        <label class="form-check-label" for="radio-box-3">Boys Jeans</label>
                                    </div>
                                    <div class="form-check mt-2">
                                        <input class="form-check-input" name="product-category" type="radio" id="radio-box-4"/>
                                        <label class="form-check-label" for="radio-box-4">Girls Jeans</label>
                                    </div>
                                </FilterCard>
                                <FilterCard title={"Product Size"}>
                                    <div class="form-check">
                                        <input class="form-check-input" name="product-category" type="checkbox" id="check-box-1"/>
                                        <label class="form-check-label" for="check-box-1">Small</label>
                                    </div>
                                    <div class="form-check mt-2">
                                        <input class="form-check-input" name="product-category" type="checkbox" id="check-box-2"/>
                                        <label class="form-check-label" for="check-box-2">Medium</label>
                                    </div>
                                    <div class="form-check mt-2">
                                        <input class="form-check-input" name="product-category" type="checkbox" id="check-box-3"/>
                                        <label class="form-check-label" for="check-box-3">Large</label>
                                    </div>
                                </FilterCard>
                                <FilterCard title={"Product Pricing"}>
                                    <div>
                                        <div>
                                            <label for="price-range" class="form-label fw-bold text-dark">Price : $40</label>
                                            <input type="range" class="form-range mt-2" min="0" max="10" step="1" name="price-range" />
                                        </div>
                                        <hr />
                                        <div className="d-flex justify-content-between mt-2">
                                            <label className="form-label mb-0">Min : $100</label>
                                            <label className="form-label mb-0">Max : $500</label>
                                        </div>
                                    </div>
                                </FilterCard>
                            </aside>
                            
                        </div>

                        <div className="col-lg-9 col-md-8 col-sm-12">

                            <div className="row">

                                <div className="col-12">
                                    <h6 className="mt-2">45 items</h6>
                                    <hr />
                                </div>

                                <ProductCardList/>
                                <ProductCardList/>
                                <ProductCardList/>
                                <ProductCardList/>
                                <ProductCardList/>
                                <ProductCardList/>
                                <ProductCardList/>
                                <ProductCardList/>
                                <ProductCardList/>
                                <ProductCardList/>
                                <ProductCardList/>
                                <ProductCardList/>
                            </div>

                        </div>

                    </div>

                </div>
            </section>
        </React.Fragment>);
    }

}

export default ProductList;