import React from "react";
import { Link } from "react-router-dom";
import BreadCrum from "../../components/BreadCrum/BreadCrum";

const Signup = () => {
    return (
    <React.Fragment>
    <BreadCrum items={['Sign up']} />
        <section className="section">
            <div className="container">

                <figure className="card card-md mx-auto">
                    <div className="card-body">

                        <div className="row">

                            <div className="col-12">
                                <div className="mb-4">
                                    <h2 className="title mb-3">Sign up</h2>
                                    <p className="description">Fill the details to register a new account</p>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="my-2">
                                    <label htmlFor="name" className="mb-2">Your name </label>
                                    <input type="text" name="name" id="name-input" className="input-group-text input-box" required />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="my-2">
                                    <label htmlFor="email" className="mb-2">Email address</label>
                                    <input type="email" name="email" id="email-input" className="input-group-text input-box" required />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="my-2">
                                    <label htmlFor="phone" className="mb-2">Phone</label>
                                    <input type="tel" minLength="10" maxLength="10" name="phone" id="phone-input" className="input-group-text input-box" required />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="my-2">
                                    <label htmlFor="password" className="mb-2">Password</label>
                                    <input type="password" minLength="6" name="password" id="password-input" className="input-group-text input-box" required />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="my-2">
                                    <label htmlFor="repeat-password" className="mb-2">Repeat password</label>
                                    <input type="password" minLength="6" name="repeat-password" id="repeat-password-input" className="input-group-text input-box" required />
                                </div>
                            </div>

                            <div className="col-12">
                                <div class="form-check my-3">
                                    <input type="checkbox" id="remember" name="remember" className="form-check-input" />
                                    <label className="form-check-label" for="remember">I accept terms & conditions</label><a href="#" className="card-link ms-1">view</a>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="my-2">
                                    <button className="btn btn-ascent w-100">Register</button>
                                </div>
                            </div>

                            <div className="col-12">
                                <div class="mt-3 text-center">
                                    <Link to="/signin" className="card-link">Already have an account ?</Link>
                                </div>
                            </div>

                        </div>

                    </div>
                </figure>

            </div>
        </section>
    </React.Fragment>)
}

export default Signup;