import React from "react";
import { Link } from "react-router-dom";
import BreadCrum from "../../components/BreadCrum/BreadCrum";
import { handleLogin } from "../../controllers/Auth";

const Signin = () => {

    const handleLoginSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        handleLogin(data.get('email'),data.get('password'));
    }

    return (
    <React.Fragment>
        <BreadCrum items={['Sign in']} />
        <section className="section">
            <div className="container">

                <form onSubmit={(event) => handleLoginSubmit(event)}>
                
                <figure className="card card-sm mx-auto">
                    <div className="card-body">

                        <div className="row">

                            <div className="col-12">
                                <div className="mb-4">
                                    <h2 className="title mb-3">Sign in</h2>
                                    <p className="description">Fill the details to login to your account</p>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="my-2">
                                    <label htmlFor="email" className="mb-2">Email address</label>
                                    <input type="email" name="email" id="email-input" className="input-group-text input-box" required />
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="my-2">
                                    <label htmlFor="password" className="mb-2">Password</label>
                                    <input type="password" name="password" id="password-input" className="input-group-text input-box" required />
                                </div>
                            </div>

                            <div className="col-6">
                                <div class="form-check my-3">
                                    <input type="checkbox" id="remember" name="remember" className="form-check-input" />
                                    <label className="form-check-label" for="remember">Remember me</label>
                                </div>
                            </div>

                            <div className="col-6">
                                <div class="my-3 text-end">
                                    <a href="#" className="card-link">Forgot password ?</a>
                                </div>
                            </div>

                            <div className="col-12">
                                <div className="my-2">
                                    <button className="btn btn-ascent w-100">Login</button>
                                </div>
                            </div>

                            <div className="col-12">
                                <div class="mt-3 text-center">
                                    <Link to="/signup" className="card-link">Doesn't have an account ?</Link>
                                </div>
                            </div>

                        </div>

                    </div>
                </figure>

                </form>

            </div>
        </section>
    </React.Fragment>)
}

export default Signin;
