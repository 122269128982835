import React from "react";
import "./ProductPage.css";
import BreadCrum from "../../components/BreadCrum/BreadCrum";
import {FaStar, AiOutlineHeart, MdOutlineAddShoppingCart} from "react-icons/all";
import { toast } from 'react-toastify';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Helmet } from "react-helmet";

class ProductPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
        this.handleAddToCart = this.handleAddToCart.bind(this);
        this.handleAddToWishlist = this.handleAddToWishlist.bind(this);
        this.handleImageZoom = this.handleImageZoom.bind(this);
    }

    componentDidMount() {
        this.setState({ nav1: this.slider1, nav2: this.slider2 });
    }

    handleAddToCart = () => toast.success("Added to cart");
    handleAddToWishlist = () => toast.success("Added to wishlist");

    handleImageZoom = (event) => {
        var container = event.target.querySelector('img');
    }

    render() {

        const PreviewCarouselSetting = {
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            asNavFor: this.state.nav2,
        };

        const ThumbnailCarouselSetting = {
            slidesToShow: 4,
            swipeToSlide: true,
            focusOnSelect: true,
            asNavFor: this.state.nav1,
        }

        return (
        <React.Fragment>
            <Helmet>
                <title>Product Name | React Ecommerc</title>
            </Helmet>
            <BreadCrum items={['Dashboard','Product']} />
            <section className="section">
                <div className="container">
                    
                    {/* First Row (Start) */}
                    <div className="row">

                        {/* Images Section (Start) */}
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="product-carousel">
                                <div className="product-carousel-preview" id="product-carousel-preview">
                                    <Slider {...PreviewCarouselSetting} ref={slider => (this.slider1 = slider)}>
                                        <div className="product-preview-div" onMouseOver={this.handleImageZoom}>
                                            <img src="images/product-img.jpg" class="img-fluid" alt="product-img"/>
                                        </div>
                                        <div className="product-preview-div" onMouseOver={this.handleImageZoom}>
                                            <img src="images/product-img-1.jpg" class="img-fluid" alt="product-img"/>
                                        </div>
                                        <div className="product-preview-div" onMouseOver={this.handleImageZoom}>
                                            <img src="images/product-img.jpg" class="img-fluid" alt="product-img"/>
                                        </div>
                                        <div className="product-preview-div" onMouseOver={this.handleImageZoom}>
                                            <img src="images/product-img.jpg" class="img-fluid" alt="product-img"/>
                                        </div>
                                        <div className="product-preview-div" onMouseOver={this.handleImageZoom}>
                                            <img src="images/product-img.jpg" class="img-fluid" alt="product-img"/>
                                        </div>
                                    </Slider>
                                </div>
                                <div className="product-carousel-thumbnaliNav">
                                    <Slider {...ThumbnailCarouselSetting} ref={slider => (this.slider2 = slider)}>
                                        <div className="product-thumbnail-div">
                                            <img src="images/product-img.jpg" class="img-fluid product-carousel-thumbnail" alt="product-img"/>
                                        </div>
                                        <div className="product-thumbnail-div">
                                            <img src="images/product-img.jpg" class="img-fluid product-carousel-thumbnail" alt="product-img"/>
                                        </div>
                                        <div className="product-thumbnail-div">
                                            <img src="images/product-img.jpg" class="img-fluid product-carousel-thumbnail" alt="product-img"/>
                                        </div>
                                        <div className="product-thumbnail-div">
                                            <img src="images/product-img.jpg" class="img-fluid product-carousel-thumbnail" alt="product-img"/>
                                        </div>
                                        <div className="product-thumbnail-div">
                                            <img src="images/product-img.jpg" class="img-fluid product-carousel-thumbnail" alt="product-img"/>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                            <br />
                        </div>
                        {/* Images Section (End) */}

                        {/* Details Section (Start)  */}
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <article className="content">

                                <figure className="title">
                                    <h3 className="product-name">Sony HD-3435 Headphones</h3>
                                </figure>

                                <figure className="ratings">
                                    <div className="rating-stars">
                                        <span className="active"><FaStar size={18}/></span>
                                        <span className="active"><FaStar size={18}/></span>
                                        <span className="active"><FaStar size={18}/></span>
                                        <span className="active"><FaStar size={18}/></span>
                                        <span className="inactive"><FaStar size={18}/></span>
                                        <label className="text-secondary mb-0 mt-1 small ms-1">(24)</label>
                                    </div>
                                </figure>
                                
                                <figure className="pricing">
                                    <h2 className="discounted-price">$59.00</h2>
                                    <h5 className="actual-price">$69.00</h5>
                                    <h5 className="discount-percentage"> 10% OFF</h5>
                                </figure>

                                <hr />

                                <figure className="highlight">
                                    <h6 className="title">Highlights</h6>
                                    <ul className="highlight-list">
                                        <li>Stylish & Portable Thin and Light Laptop</li>
                                        <li>14 inch Full HD WVA AG Narrow Border Display</li>
                                        <li>Light Laptop without Optical Disk Drive</li>
                                    </ul>
                                </figure>

                                <figure className="delivery">
                                    <h6 className="title">Delivery to</h6>
                                    <div className="input-group my-2">
                                        <input type="number" name="pincode" id="pincode" className="input-group-text input-box w-auto" placeholder="Pincode" />
                                        <button className="btn btn-ascent">Check</button>
                                    </div>
                                    <p className="text-secondary">Standard delivery in 4 - 5 days</p>
                                </figure>

                                <hr />

                                <figure className="size">
                                    <h6 className="title">Availabe sizes</h6>
                                    <ul className="size-list list-unstyled mb-2">
                                        <li><button className="size-tab">SM</button></li>
                                        <li><button className="size-tab">MD</button></li>
                                        <li><button className="size-tab">XL</button></li>
                                    </ul>
                                </figure>

                                <figure className="actions d-none d-lg-block d-md-block">
                                    <button onClick={this.handleAddToCart} className="btn btn-lg btn-ascent px-4 me-2"><span className="me-1"><MdOutlineAddShoppingCart size={20}/></span>Add to cart</button>
                                    <button onClick={this.handleAddToWishlist} className="btn btn-lg btn-pink px-4"><span className="me-1"><AiOutlineHeart size={20}/></span> Add to wishlist</button>
                                </figure>

                                <hr />

                                <figure className="category">
                                    <h6 className="title">Category</h6>
                                    <div className="mt-2">
                                        <a href="#" className="card-link">Headphones</a>
                                    </div>
                                </figure>

                                <figure className="tags">
                                    <h6 className="title">Tags</h6>
                                    <div className="mt-1">
                                        <a href="#" className="tag">Headphones</a>
                                        <a href="#" className="tag">Sony</a>
                                        <a href="#" className="tag">Handsfree</a>
                                        <a href="#" className="tag">Earphones</a>
                                    </div>
                                </figure>

                            </article>
                        </div>
                        {/* Details Section (End)  */}

                        <div className="col-12 d-block d-lg-none d-md-none">
                            <button onClick={this.handleAddToCart} className="btn my-2 btn-lg w-100 btn-ascent me-2"><span className="me-1"><MdOutlineAddShoppingCart size={20}/></span>Add to cart</button>
                            <button onClick={this.handleAddToWishlist} className="btn my-2 btn-lg w-100 btn-pink"><span className="me-1"><AiOutlineHeart size={20}/></span> Add to wishlist</button>
                        </div>

                    </div>
                    {/* First Row (End) */}


                    {/* Second Row (Start) */}
                    <div className="row">

                        <div className="col-12">
                            <hr />
                            <h6 className="title mb-3">Description</h6>
                            <article className="description">

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Nisl purus in mollis nunc sed. Nam at lectus urna duis convallis convallis tellus id. Habitant morbi tristique senectus et netus et malesuada fames. Amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus et. Pretium vulputate sapien nec sagittis aliquam malesuada bibendum. Odio facilisis mauris sit amet massa. Malesuada fames ac turpis egestas. Accumsan sit amet nulla facilisi. Tristique senectus et netus et malesuada fames ac turpis egestas. Nisl suscipit adipiscing bibendum est. Pharetra sit amet aliquam id diam maecenas ultricies mi eget. Massa tincidunt dui ut ornare. Nec nam aliquam sem et. Enim lobortis scelerisque fermentum dui faucibus in ornare quam. Commodo sed egestas egestas fringilla phasellus. Amet massa vitae tortor condimentum lacinia quis vel eros. Scelerisque felis imperdiet proin fermentum leo vel orci.
                                <br /><br />
                                Mattis pellentesque id nibh tortor. Porttitor rhoncus dolor purus non. Nisl purus in mollis nunc sed id semper risus. Proin nibh nisl condimentum id venenatis a condimentum vitae. Nunc faucibus a pellentesque sit amet porttitor eget dolor. Mattis nunc sed blandit libero volutpat. Turpis massa sed elementum tempus egestas sed sed. Neque aliquam vestibulum morbi blandit cursus risus at. Congue mauris rhoncus aenean vel elit scelerisque mauris pellentesque pulvinar. Praesent elementum facilisis leo vel fringilla est ullamcorper eget. Ultrices gravida dictum fusce ut placerat orci nulla pellentesque dignissim. Phasellus egestas tellus rutrum tellus pellentesque eu tincidunt. At risus viverra adipiscing at in tellus. Hendrerit gravida rutrum quisque non tellus orci. Sit amet consectetur adipiscing elit ut. Diam quis enim lobortis scelerisque fermentum. Eget egestas purus viverra accumsan in. Dui vivamus arcu felis bibendum ut. Nec tincidunt praesent semper feugiat nibh. Eu nisl nunc mi ipsum faucibus vitae aliquet nec ullamcorper.
                                <br /><br />
                                Auctor urna nunc id cursus. Eget velit aliquet sagittis id consectetur purus ut. Ipsum faucibus vitae aliquet nec ullamcorper sit amet. Enim nec dui nunc mattis enim. Quis viverra nibh cras pulvinar mattis nunc sed blandit. Purus ut faucibus pulvinar elementum integer enim neque volutpat. Mattis pellentesque id nibh tortor. Varius sit amet mattis vulputate enim nulla aliquet porttitor. Aliquet nibh praesent tristique magna sit amet purus. Ornare lectus sit amet est placerat in. Eget est lorem ipsum dolor sit amet consectetur adipiscing. Ultrices in iaculis nunc sed. Amet nisl suscipit adipiscing bibendum est. Justo laoreet sit amet cursus sit amet dictum. Sem nulla pharetra diam sit amet nisl suscipit adipiscing. Tincidunt praesent semper feugiat nibh.</p>
                                
                            </article>
                        </div>

                    </div>
                    {/* Second Row (End) */}

                

                </div>
            </section>
        </React.Fragment>);
    }
}

export default ProductPage;