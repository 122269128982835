import React ,{ useEffect, useHistory }from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import { useLocation, Routes, Route  } from "react-router-dom";
import "./App.css";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import Pages and Components
import Home from "./pages/Home/Home";
import Signup from "./pages/Auth/Signup";
import Signin from "./pages/Auth/Signin";
import ProductPage from "./pages/ProductPage/ProductPage";
import DashboardAccount from "./pages/Dashboard/DashboardAccount";
import DashboardAddressBook from "./pages/Dashboard/DashboardAddressBook";
import DashboardAddAddress from "./pages/Dashboard/DashboardAddAddress";
import DashboardOrders from "./pages/Dashboard/DashboardOrders";
import DashboardSettings from "./pages/Dashboard/DashboardSettings";
import DashboardOrderDetails from "./pages/Dashboard/DashboardOrderDetails";
import ProductList from "./pages/ProductList/ProductList";
import Cart from "./pages/Cart/Cart";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";   

const App = () => {

    const location = useLocation();
    const path = location.pathname;

    useEffect( () => { window.scrollTo(0, 0); });

    return (
    <React.Fragment>
        <Header/>
        <main className="main">
            <Routes>
                <Route exact path="/" element={<Home/>} />
                <Route exact path="/signin" element={<Signin/>} />
                <Route exact path="/signup" element={<Signup/>} />
                <Route exact path="/product" element={<ProductPage/>} />
                <Route exact path="/product/list" element={<ProductList/>} />
                <Route exact path="/cart" element={<Cart/>} />
                <Route exact path="/dashboard/account" element={<DashboardAccount/>} />
                <Route exact path="/dashboard/address" element={<DashboardAddressBook/>} />
                <Route exact path="/dashboard/address/add" element={<DashboardAddAddress/>} />
                <Route exact path="/dashboard/orders/" element={<DashboardOrders/>} />
                <Route exact path="/dashboard/orders/details" element={<DashboardOrderDetails/>} />
                <Route exact path="/dashboard/settings/" element={<DashboardSettings/>} />
            </Routes>
        </main>
        <Footer/>    
        
    <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnHover={false} />
    </React.Fragment>);
    

}

export default App;