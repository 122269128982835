import React from "react";
import BreadCrum from "../../components/BreadCrum/BreadCrum";
import DashboardSidebar from "./DashboardSidebar";
import "./Dashboard.css";
import { IoIosMail, IoIosCall } from "react-icons/all";
import { Helmet } from "react-helmet";

const DashboardAccount = () => {
    return (
    <React.Fragment>
        <Helmet>
            <title>My Account | React Ecommerce</title>
        </Helmet>
        <BreadCrum items={['Dashboard','My Account']} />
        <section className="section">
            <div className="container">
    
                <div className="row">

                    <DashboardSidebar/>

                    {/* Panels (Start) */}
                    <div className="col-lg-9 col-md-9 col-sm-12">
                        <div className="card dashboard-card">
                            <div className="card-header">
                                <h4 className="title">My Account</h4>
                            </div>
                            <div className="card-body p-0">

                                <div className="card">
                                    <div className="card-body">
                                        <h6 className="title">Labhansh Satpute</h6>
                                        <p className="description"><span className="me-1"><IoIosMail size={19} /></span>labhansh25@gmail.com</p>
                                        <p className="description"><span className="me-1"><IoIosCall size={19} /></span>+91-9876543210</p>
                                    </div>
                                </div>

                            </div>
                            <div className="card-footer">
                                <button className="btn btn-ascent">Edit Details</button>
                            </div>
                        </div>
                    </div>
                    {/* Panels (End) */}

                </div>

            </div>
        </section>
    </React.Fragment>);
}

export default DashboardAccount;