import React from "react";
import BreadCrum from "../../components/BreadCrum/BreadCrum";
import DashboardSidebar from "./DashboardSidebar";
import "./Dashboard.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const DashboardAddressBook = () => {
    return (
    <React.Fragment>
        <Helmet>
            <title>Address Book | React Ecommerce</title>
        </Helmet>
        <BreadCrum items={['Dashboard','Address Book']} />
        <section className="section">
            <div className="container">
    
                <div className="row">

                    <DashboardSidebar/>

                    {/* Panels (Start) */}
                    <div className="col-lg-9 col-md-9 col-sm-12">
                        <div className="card dashboard-card">
                            <div className="card-header">
                                <h4 className="title">Address Book</h4>
                            </div>
                            <div className="card-body p-0">

                                <div className="card address-card">
                                    <div className="card-body">
                                        <div className="float-end">
                                            <a href="#" className="card-link">Edit</a>
                                            <a href="#" className="card-link">Delete</a>
                                        </div>
                                        <h6 className="title">Home</h6>
                                        <p className="description">Labhansh Satpute</p>
                                        <p className="description">+91-9876543219, +91-9876543219</p>
                                        <p className="description">Near Post Office, Sector 36, Noida - 123456</p>
                                    </div>
                                </div>

                            </div>
                            <div className="card-footer">
                                <Link to={"/dashboard/address/add"} className="btn btn-ascent">Add Address</Link>
                            </div>
                        </div>
                    </div>
                    {/* Panels (End) */}

                </div>

            </div>
        </section>
    </React.Fragment>);
}

export default DashboardAddressBook;