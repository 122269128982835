import React from "react";
import BreadCrum from "../../components/BreadCrum/BreadCrum";
import DashboardSidebar from "./DashboardSidebar";
import "./Dashboard.css";

const DashboardSettings = () => {
    return (
    <React.Fragment>
        <BreadCrum items={['Dashboard','Account Settings']} />
        <section className="section">
            <div className="container">
    
                <div className="row">

                    <DashboardSidebar/>

                    {/* Panels (Start) */}
                    <div className="col-lg-9 col-md-9 col-sm-12">
                        <div className="card dashboard-card">
                            <div className="card-header">
                                <h4 className="title">Account Settings</h4>
                            </div>
                            <div className="card-body">

                                <div className="row">

                                    <div className="col-12">                    
                                        <h6 className="title">Personal Details</h6>
                                        <hr />

                                        <div className="row">
                                                                
                                            <div className="col-lg-5 col-md-5 col-12">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="first-name">First name</label>
                                                    <input type="text" name="first-name" id="first-name" className="input-group-text input-box" required />
                                                </div>
                                            </div>

                                            <div className="col-lg-5 col-md-5 col-12">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="last-name">Last name</label>
                                                    <input type="text" name="last-name" id="last-name" className="input-group-text input-box" required />
                                                </div>
                                            </div>

                                            <div className="col-lg-5 col-md-5 col-12">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="date-of-birth">Date of birth</label>
                                                    <input type="date" name="date-of-birth" id="date-of-birth" className="input-group-text input-box" required />
                                                </div>
                                            </div>

                                            <div className="col-lg-5 col-md-5 col-12">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="gender">Gender</label>
                                                    <div className="mt-2 d-flex">
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="gender" id="gender-male" />
                                                            <label className="form-check-label" htmlFor="gender-male">Male</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="gender" id="gender-female" />
                                                            <label className="form-check-label" htmlFor="gender-female">Female</label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="gender" id="gender-other" />
                                                            <label className="form-check-label" htmlFor="gender-other">Other</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <h6 className="mt-5 title">Contact Details</h6>
                                        <hr />

                                        <div className="row">
                                                                
                                            <div className="col-lg-5 col-md-5 col-12">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="email">Email address</label>
                                                    <input type="email" name="email" id="email" className="input-group-text input-box" required />
                                                </div>
                                            </div>

                                            <div className="col-lg-5 col-md-5 col-12">
                                                <div className="mb-3">
                                                    <label className="form-label" htmlFor="phone">Phone</label>
                                                    <input type="tel" minLength="10" maxLength="10" name="phone" id="phone" className="input-group-text input-box" required />
                                                </div>
                                            </div>

                                            <div className="col-lg-5 col-md-5 col-12">
                                                <div className="mb-0">
                                                    <div className="form-check mt-2">
                                                        <input className="form-check-input" type="checkbox" value="" id="email-newsletters" />
                                                        <label className="form-check-label" htmlFor="email-newsletters">Receive newsletters and updates</label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <p className="mt-4 text-secondary small">Note : Lorem ipsum, dolor sit amet consectetur adipisicing elit. Exercitationem consequuntur laboriosam, molestiae architecto voluptates ratione odio esse aliquid suscipit minus voluptate, eius quae quaerat odit tempore! Culpa iure esse temporibus?</p>
                                    </div>

                                </div>

                            </div>
                            <div className="card-footer">
                                <button className="btn btn-ascent">Save changes</button>
                            </div>
                        </div>
                    </div>
                    {/* Panels (End) */}

                </div>

            </div>
        </section>
    </React.Fragment>);
}

export default DashboardSettings;